<template>
  <div class="address-box">
    <div class="head">
      <div style="color: #000; font-weight: 550; width: 16%">{{ $t('address.AddressSelect.title') }}</div>
      <div v-if="stateCurrent" style="width: 70%">{{ chooseAddr }}</div>
      <div class="pointer" style="color: #3C78AB; font-size: 1.4rem;" v-show="expand" @click="handleCreate">{{ $t('address.AddressSelect.add') }}</div>
      <div class="pointer" style="color: #C7C7C7; font-size: 1.6rem" v-show="!expand" @click="expand=true">{{ $t('address.AddressSelect.expand') }}</div>
    </div>
    <div class="addr-box" v-show="expand">
      <div class="addr-item" v-for="item in options" :key="item.Id">
        <div @click="onNav(item)" class="overview pointer" :class="{ 'btn-active': item.Id == stateCurrent }">
          <div class="badge" v-if="item.Id == stateCurrent">
            <i class="el-icon-check icon-badge"></i>
          </div>
          {{ `${item.RecipientName} ${item.DestinationName}` }}
        </div>
        <div class="addr-detail">
          <div class="address-type" :style="{ background: item.Type ===2 ? '#24ab37' : '#e00101' }">{{ item.TypeText }}</div>
          <el-tooltip class="item" effect="dark" :content="`${item.RecipientName} ${item.DestinationName} ${item.ProvinceName || ''} ${item.CityName || ''} ${item.AreaName || ''} ${item.RecipientAddress}`" placement="right">
            <div class="addr-text">
              {{ `${item.RecipientName} ${item.DestinationName} ${item.ProvinceName || ""} ${item.CityName || ""} ${item.AreaName || ""} ${item.RecipientAddress}` }}
            </div>
          </el-tooltip>
        </div>
        <div class="operation">
          <span class="pointer mr15" @click="handleUpdate(item)">{{ $t('address.AddressSelect.edit') }}</span>
          <span class="pointer" @click="deleteAddress(item.Id)">{{ $t('address.AddressSelect.delete') }}</span>
        </div>
      </div>
    </div>
    <div class="pointer" v-show="expand" style="color: #C7C7C7; font-size: 1.6rem" @click="expand = false">{{ $t('address.AddressSelect.retract') }}<i class="el-icon-arrow-up"></i></div>
    <address-operation
        ref="operation"
        @handle="operationSuccess"
        @close="closeVisible"
        :body="true"
        :show="addressVisible"
        :dialog-status="dialogStatus"
        :temp="temp">
    </address-operation>
  </div>
</template>

<script>
import AddressOperation from "@/components/AddressOperation.vue";
import {deleteAddress} from "@/api/member";
export default {
  name: "AddressSelect",
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      stateCurrent: undefined,
      expand: true,
      addressVisible: false,
      dialogStatus: "",
      temp: {}
    }
  },
  computed: {
    chooseAddr() {
      if (this.stateCurrent) {
        let result = ""
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].Id === this.stateCurrent) {
            result = `${this.options[i].RecipientName} ${this.options[i].RecipientPhone} ${this.options[i].DestinationName} ${this.options[i].ProvinceName || ""} ${this.options[i].CityName || ""} ${this.options[i].AreaName || ""} ${this.options[i].RecipientAddress}`
          }
        }
        return result
      } else {
        return ""
      }
    },
  },
  components: {
    AddressOperation
  },
  methods: {
    closeVisible() {
      this.addressVisible = false
    },
    onNav(item) {
      this.$emit('select-address', item.Id)
      this.stateCurrent = item.Id
    },
    operationSuccess() {
      this.addressVisible = false
      this.$emit('refresh')
    },
    deleteAddress(rowId) {
      this.$confirm(this.$t('address.confirmDelete'), this.$t('address.common.prompt'), {
        confirmButtonText: this.$t('address.common.confirm'),
        cancelButtonText: this.$t('address.common.cancel'),
        type: 'warning'
      }).then(() => {
        deleteAddress([rowId]).then(response => {
          if (response.Success) {
            this.$message({
              type: 'success',
              message: this.$t('address.deleteSuccess')
            });
            this.$emit('refresh')
            this.onNav({ Id: undefined })
          } else {
            this.$message({
              type: 'warning',
              message: response.Msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('domestic.cancelDelete')
        });
      });
    },
    async handleUpdate(row) {
      this.temp = await Object.assign({}, row)
      await this.$refs.operation.getCountry(row.Type === 2)
      await this.$refs.operation.fetchAddress('destination', row['DestinationId'])
      await this.$refs.operation.fetchAddress('province', row['ProvinceId'])
      await this.$refs.operation.fetchAddress('city', row['CityId'])
      if (row.Type === 2) await this.$refs.operation.fetchAddress('area', row['AreaId'])
      this.dialogStatus = 'update'
      this.addressVisible = true;
      this.$nextTick(() => {
        this.$refs.operation.clearValidate()
      })
    },
    resetTemp() {
      this.temp = {
        DestinationId: "",
        ProvinceId: "",
        CityId: "",
        Area: "",
        Type: 1,
        AgentPointId: "",
        RecipientName: "",
        RecipientPhone: "",
        RecipientAddress: "",
        serviceType: ""
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.addressVisible = true
      this.$nextTick(() => {
        this.$refs.operation.clearValidate()
      })
    },
  }
}
</script>

<style scoped lang="scss">
/* 定义滚动条的宽度 */
::-webkit-scrollbar {
  width: 10px;
}

/* 定义滚动条轨道的颜色 */
::-webkit-scrollbar-track {
  background: #EBEBEB;
  border-radius: 10px;
}

/* 定义滚动条的颜色 */
::-webkit-scrollbar-thumb {
  background: #69AAD6;
  border-radius: 10px;
}

/* 当鼠标悬停在滚动条上时改变其颜色 */
::-webkit-scrollbar-thumb:hover {
  background: #194D8F;
}
.address-box {
  border: 1px solid #D3D6DB;
  padding: 1.2rem;
  margin: 1rem 0;
  font-size: 1.5rem;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    margin-bottom: 1.5rem;
  }
  .addr-box {
    padding: 0 15px;
    height: 16rem;
    overflow-y: scroll;
    .addr-item:hover {
      background-color: #FFF3F3;
    }
    .addr-item {
      margin: 1rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-active {
        border: 2px solid #E4393C !important;
      }
      .overview {
        text-align: center;
        border: 2px solid #EBEBEB;
        padding: 0.8rem;
        width: 15rem;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        .badge {
          position: absolute;
          bottom: 0;
          right: -4rem;
          width: 8rem;
          text-align: center;
          background-color: #E4393C;
          transform: rotate(-45deg) translateY(0.4rem);
          transform-origin: bottom center;
          color: #ffffff;
          font-size: 1.5rem;
          .icon-badge {
            transform: rotate(45deg);
            padding: 0 5px 5px 0;
          }
        }
      }
      .addr-detail {
        width: calc(100% - 32rem);
        display: flex;
        align-items: center;
        .addr-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .address-type {
          background-color: #24ab37;
          white-space: nowrap;
          padding: 5px;
          border-radius: 5px;
          font-size: 1.2rem;
          color: #fff;
          margin-right: 8px;
        }
      }
      .operation {
        width: 12rem;
        color: #3C78AB;
        font-size: 1.5rem;
      }
    }
  }
}
</style>
