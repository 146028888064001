<template>
  <div class="box">
    <div class="item">
      <div class="label">{{ $t('pickupPoint.Name') }}：{{ item.Name }}</div>
      <div class="label">{{ $t('pickupPoint.Address') }}：{{ fullAddress }}</div>
    </div>
    <div class="item">{{ $t('pickupPoint.Rem') }}：{{ item.Rem }}</div>
    <div class="item">
      <div class="label w33">{{ $t('pickupPoint.Phone') }}：{{ item.Phone }}</div>
      <div class="label w33">{{ $t('pickupPoint.Tel') }}：{{ item.Tel }}</div>
      <div class="label w33">{{ $t('pickupPoint.Postcode') }}：{{ item.Postcode }}</div>
    </div>
    <div class="item">
      <div class="label">{{ $t('pickupPoint.ServiceCharge') }}：{{ item.ServiceCharge }}</div>
      <div class="label">{{ $t('pickupPoint.StorageLife') }}：{{ item.StorageLife }}</div>
    </div>
    <div class="item">
      <div style="color:#194D8F;" class="label w33">{{ $t('pickupPoint.AgentStateText') }}：{{ item.AgentStateText }}</div>
      <div class="label w33">{{ $t('pickupPoint.Longitude') }}：{{ item.Longitude }}</div>
      <div class="label w33">{{ $t('pickupPoint.Latitude') }}：{{ item.Latitude }}</div>
    </div>
    <el-button size="mini" type="text" @click="addToAddress">{{ $t('pickupPoint.Add') }}</el-button>
    <address-operation
        ref="operation"
        @handle="operationSuccess"
        @close="closeVisible"
        :body="true"
        :show="addressVisible"
        :dialog-status="dialogStatus"
        :temp="temp">
    </address-operation>
  </div>
</template>

<script>
import AddressOperation from "@/components/AddressOperation.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      addressVisible: false,
      dialogStatus: "create",
      temp: {}
    }
  },
  components: {
    AddressOperation
  },
  computed: {
    fullAddress() {
      return `${this.item.DestinationName}${this.item.ProvinceName || ""}${this.item.CityName || ""}${this.item.AreaName || ""}${this.item.Address}`
    }
  },
  methods: {
    operationSuccess() {
      this.addressVisible = false
    },
    closeVisible() {
      this.addressVisible = false
    },
    async addToAddress() {
      this.temp = JSON.parse(JSON.stringify(this.item))
      this.temp['Type'] = this.temp['AddressType']
      this.temp['AgentPointId'] = this.temp['Id']
      this.temp['AgentPointName'] = this.temp['Name']
      this.temp['RecipientAddress'] = this.temp['Address']
      await this.$refs.operation.getCountry(true)
      await this.$refs.operation.fetchAddress('destination', this.temp['DestinationId'])
      await this.$refs.operation.fetchAddress('province', this.temp['ProvinceId'])
      await this.$refs.operation.fetchAddress('city', this.temp['CityId'])
      await this.$refs.operation.fetchAddress('area', this.temp['AreaId'])
      this.addressVisible = true
      this.$nextTick(() => {
        this.$refs.operation.clearValidate()
      })
    }
  }
}

</script>

<style scoped lang="scss">
.box {
  padding: 0.8rem;
  border: 1px solid #DCDFE6;
  border-radius: 1rem;
  .item {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 0.8rem 0;
    .label {
      width: 50%;
    }
    .w33 {
      width: 33% !important;
    }
  }
}
</style>