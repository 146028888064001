<template>
  <div class="card">
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Length']" placeholder="长"></el-input>
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Width']" placeholder="宽"></el-input>
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Height']" placeholder="高"></el-input>
    <el-input size="mini" style="width: 25%" v-model="lineSize[index]['Quantity']" placeholder="数量"></el-input>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['lineSize'])
  },
  data() {
    return {
    }
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
.card {
  margin-bottom: 1rem;
}
</style>
