<template>
  <div class="card">
    <el-input size="mini" style="width: 33%" v-model="lineWeight[index]['GrossWeight']" placeholder="毛重"></el-input>
    <el-input size="mini" style="width: 33%" v-model="lineWeight[index]['TareWeight']" placeholder="皮重"></el-input>
    <el-input size="mini" style="width: 33%" v-model="lineWeight[index]['Quantity']" placeholder="数量"></el-input>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['lineWeight'])
  },
  data() {
    return {
    }
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
.card {
  margin-bottom: 1rem;
}
</style>
